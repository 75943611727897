<template>
  <div class="crud_form_wrapper single_show_content_wrapper">
    <!-- Start:: Title -->
    <div class="form_title_wrapper">
      <h4>{{ $t("PLACEHOLDERS.view_alert_data") }}</h4>
    </div>
    <!-- End:: Title -->

    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">

      <!-- ==== Start:: Client Main Data ==== -->
      <form>
        <div class="row">

          <!-- Start:: Ar Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.sender_name')" v-model.trim="data.sender_name"
            disabled class="disabled_input" />
          <!-- End:: Ar Name Input -->

          <!-- Start:: Phone Input -->

           <base-select-input col="6" :optionsList="recipient" :placeholder="$t('PLACEHOLDERS.recipient_name')"
            v-model="data.recipient_name"  :label="'name'" :trackBy="'id'"
            multiple  disabled/>
          <!-- End:: Phone Input -->

          <!-- Start:: creation_date Input -->
          <base-input col="6" type="email" :placeholder="$t('PLACEHOLDERS.creation_date')"
            v-model.trim="data.creation_date" readonly class="disabled_input" />
          <!-- End:: creation_date Input -->

          <!-- Start:: alert_title Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.alert_title')" v-model.trim="data.alert_title"
            readonly class="disabled_input" />
          <!-- End:: alert_title Input -->

          <!-- Start:: alert_content Input -->
          <base-input col="6"  v-if="data.alert_content" type="text" :placeholder="$t('PLACEHOLDERS.alert_content')"
            v-model.trim="data.alert_content" readonly class="disabled_input" />
          <!-- End:: alert_content Input -->

          <div class="icon" v-if="data.file">
            <a :href="data.file" target="_blank" rel="noopener noreferrer">
              <i class="fas fa-file-pdf"></i>
            </a>
          </div>

          <div class="audio_file" v-if="data.voicenote">
            <audio controls>
              <source :src="data.voicenote" type="audio/ogg">
              <source :src="data.voicenote" type="audio/mpeg">
              <source :src="data.voicenote" type="audio/mp3">
              Your browser does not support the audio tag.
            </audio>
          </div>

            <!-- Start:: alert_content Input -->
          <base-input col="6"   type="text" :placeholder="$t('PLACEHOLDERS.alert_type')"
            v-model.trim="data.trans_type" readonly class="disabled_input" />
          <!-- End:: alert_content Input -->

            <div class="col-12"
            >
            <div class="row align-items-center" v-if="data.alert_type == 'tabular'">
              <!-- Start:: alert_time Input -->
              <base-input  col="6" type="text" :placeholder="$t('PLACEHOLDERS.alert_time')"
                v-model.trim="data.alert_time" readonly />
              <!-- End:: alert_time Input -->

               <!-- Start:: type_date Input -->
              <base-input  col="6" type="text"  :placeholder="$t('PLACEHOLDERS.type_date')"
                v-model.trim="data.type_date" readonly />
              <!-- End:: type_date Input -->

                <!-- Start:: date_alert Input -->
              <base-input  col="6" type="text" :placeholder="$t('PLACEHOLDERS.date_alert')"
                v-model.trim="data.date_alert" readonly />
              <!-- End:: date_alert Input -->

               <!-- Start:: alert_typeDay Input -->
              <base-input  col="6" type="text" :placeholder="$t('PLACEHOLDERS.alert_typeDay')"
                v-model.trim="data.alert_typeDay" readonly />
              <!-- End:: alert_typeDay Input -->

                 <!-- Start:: alert_repeat Input -->
              <base-input  col="6" type="text" :placeholder="$t('PLACEHOLDERS.alert_repeat')"
                v-model.trim="data.alert_repeat" readonly />
              <!-- End:: alert_repeat Input -->

              

            </div>
            </div>

            <div  v-if="isVideo" >
              <video :src="data.image.path" col="12" controls width="300" height="250"></video>
            </div>
            <div  v-else-if="data.image.path">
              <base-image-upload-input col="12" identifier="client_image" :placeholder="$t('PLACEHOLDERS.alert_image')"
              :preSelectedImage="data.image.path" disabled class="disabled_input" />
            </div>

            
        </div>
      </form>
      <!-- ==== End:: Client Main Data ==== -->

    </div>
    <!-- End:: Single Step Form Content -->
  </div>
</template>

<script>
export default {
  name: "SingleClient",

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      // Start:: Loading Data
      isWaitingRequest: false,
      // End:: Loading Data

      // Start:: Data
      data: {
        image: {
          path: null,
          file: null
        },
        sender_name: null,
        recipient_name: null,
        creation_date: null,
        alert_title: null,
        alert_content: null,
        voicenote: null,
        file: null,
        type: null,
        alert_type: null,
        trans_type: null,
        alert_time: null,
        type_date: null,
        date_alert: null,
        alert_typeDay: null,
        alert_repeat: null,
      },
      // End:: Data
      recipient: []
    };
  },
  computed: {
    isVideo() {
      const extension = this.data.image.path.split('.').pop().toLowerCase();
      return extension === 'mp4';
    }
  },

  methods: {
    // Start:: Get Data To Show
    async getDataToShow() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `reminders/${this.$route.params.id}`,
        });
        // console.log("DATA =>", res.data.data);
        this.data.sender_name = res.data.data.Reminder.sender.name;
        this.data.recipient_name = res.data.data.Reminder.receiver;
        this.data.creation_date = res.data.data.Reminder.created_at;
        this.data.alert_title = res.data.data.Reminder.title;
        this.data.alert_content = res.data.data.Reminder.body_text;
        this.data.voicenote =  res.data.data.Reminder.voicenote;
        this.data.image.path = res.data.data.Reminder.image;
        this.data.file = res.data.data.Reminder.file;
        this.data.trans_type = res.data.data.Reminder.trans_type;
        this.data.alert_type = res.data.data.Reminder.type;
        this.data.alert_time = res.data.data.Reminder.send_time[0];
        this.data.type_date = res.data.data.Reminder.hijri_date;
        this.data.date_alert = res.data.data.Reminder.first_start_date;
        this.data.alert_typeDay = res.data.data.Reminder.trans_repeat_type;
        this.data.alert_repeat = res.data.data.Reminder.repaet_count;
        if (res.data.data.Reminder.hijri_date === null) {
            this.data.type_date = "ميلادي"; 
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
    // End:: Get Data To Show
  },

  created() {
    // Start:: Fire Methods
    this.getDataToShow();
    // End:: Fire Methods
  },
};
</script>


<style lang="scss" scoped>
.icon {
  font-size: 50px;
}
</style>
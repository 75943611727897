<template>
  <div class="crud_form_wrapper">
    <!-- Start:: Title -->
    <div class="form_title_wrapper">
      <h4>{{ $t("TITLES.editCoupon") }}</h4>
    </div>
    <!-- End:: Title -->

    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row">

          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameAr')" v-model.trim="data.nameAr"
            @input="validateInput" required />
          <!-- End:: Name Input -->

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameEn')" v-model.trim="data.nameEn"
            @input="removeArabicCharacters" required />

          <!-- Start:: coupon_code Input -->
          <base-input col="6" type="text" :placeholder="$t('TITLES.coupon_code')" v-model.trim="data.coupon_code"
            required />
          <!-- End:: coupon_code Input -->

          <base-select-input col="6" :optionsList="coupon_usage_duration"
            :placeholder="$t('PLACEHOLDERS.coupon_usage_duration')" v-model="data.duration" />

          <base-input v-if="data.duration && data.duration.value == 'limited'" col="6" type="number"
            :placeholder="$t('PLACEHOLDERS.subscription_duration')" v-model.trim="data.subscription_duration" required />

          <base-select-input col="6" :optionsList="clients" :placeholder="$t('PLACEHOLDERS.coupon_users')"
            v-model="data.clients" required multiple />

          <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.max_coupon_users')"
            v-model.trim="data.max_coupon_users" required />

          <!-- Start:: Deactivate Switch Input -->
          <div class="input_wrapper switch_wrapper my-5">
            <v-switch color="green" :label="data.active ? $t('PLACEHOLDERS.active') : $t('PLACEHOLDERS.notActive')"
              v-model="data.active" hide-details></v-switch>
          </div>
          <!-- End:: Deactivate Switch Input -->

          <!-- Start:: Submit Button Wrapper -->
          <div class="btn_wrapper">
            <base-button class="mt-2" styleType="primary_btn" :btnText="$t('BUTTONS.save')" :isLoading="isWaitingRequest"
              :disabled="isWaitingRequest" />
          </div>
          <!-- End:: Submit Button Wrapper -->
        </div>
      </form>
    </div>
    <!-- END:: Single Step Form Content -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "CreateCoupon",

  data() {
    return {
      // Start:: Loader Control Data
      isWaitingRequest: false,
      // End:: Loader Control Data

      // Start:: Data Collection To Send
      data: {
        nameAr: null,
        nameEn: null,
        coupon_code: null,
        duration: null,
        subscription_duration: null,
        clients: [],
        max_coupon_users: null,
        active: true,
      },
      clients: [],
      // End:: Data Collection To Send

      arabicRegex: /^[\u0600-\u06FF\s]+$/,
      EnRegex: /[\u0600-\u06FF]/,
    };
  },

  computed: {
    // Start:: Vuex Getters
    ...mapGetters({
      allStores: "ApiGetsModule/allStoresData",
    }),
    // End:: Vuex Getters

    coupon_usage_duration() {
      return [
        {
          id: 1,
          name: this.$t("PLACEHOLDERS.limited_period"),
          value: "limited",
        },
        {
          id: 2,
          name: this.$t("PLACEHOLDERS.unlimited_period"),
          value: "unlimited ",
        }
      ];
    },

  },

  methods: {

    validateInput() {
      // Remove non-Arabic characters from the input
      this.data.nameAr = this.data.nameAr.replace(/[^\u0600-\u06FF\s]/g, "");
    },
    removeArabicCharacters() {
      this.data.nameEn = this.data.nameEn.replace(this.EnRegex, "");
    },

    // Start:: Datepicker Disable Previous Days
    disabledDate(current) {
      return current && current < moment().startOf("day");
    },
    // End:: Datepicker Disable Previous Days

    // Start:: validate Form Inputs
    validateFormInputs() {
      this.isWaitingRequest = true;

      if (!this.data.nameAr) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.name_ar"));
        return;
      } else {
        this.submitForm();
        return;
      }
    },
    // End:: validate Form Inputs

    // Start:: Submit Form
    async submitForm() {
      const REQUEST_DATA = new FormData();
      // Start:: Append Request Data
      REQUEST_DATA.append("name[ar]", this.data.nameAr);
      REQUEST_DATA.append("name[en]", this.data.nameEn);
      REQUEST_DATA.append("code", this.data.coupon_code);
      REQUEST_DATA.append("limit", this.data.max_coupon_users);
      REQUEST_DATA.append("subscription_duration", this.data.subscription_duration);
      REQUEST_DATA.append("type", this.data.duration?.value);
      if (this.data.clients.length) {
        this.data.clients.forEach((element, index) => {
          REQUEST_DATA.append(`users[${index}]`, element.id);
        });
      }
      REQUEST_DATA.append("is_active", +this.data.active);
      REQUEST_DATA.append("_method", "PUT");
      // Start:: Append Request Data

      try {
        await this.$axios({
          method: "POST",
          url: `coupons/${this.$route.params.id}`,
          data: REQUEST_DATA,
        });
        this.isWaitingRequest = false;
        this.$message.success(this.$t("MESSAGES.editedSuccessfully"));
        this.$router.push({ path: "/coupons/all" });
      } catch (error) {
        this.isWaitingRequest = false;
        this.$message.error(error.response.data.message);
      }
    },
    // End:: Submit Form

    async getClients() {
      this.loading = true;
      try {
        let res = await this.$axios({
          method: "GET",
          url: "notification/clients",
        });
        this.loading = false;
        this.clients = res.data.data;
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },

    // Start:: Get Data To Edit
    async getDataToEdit() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `coupons/${this.$route.params.id}`,
        });
        // Start:: Set Data
        this.data.nameAr = res.data.data.Coupon.name_ar;
        this.data.nameEn = res.data.data.Coupon.name_en;
        this.data.clients = res.data.data.Coupon.users;
        this.data.coupon_code = res.data.data.Coupon.code;
        this.data.max_coupon_users = res.data.data.Coupon.limit_for_use;

        this.data.duration = res.data.data.Coupon.type;
        this.data.subscription_duration = res.data.data.Coupon.subscription_duration;

        if (this.data.duration == 'limited') {
          this.data.duration = {
            id: 1,
            name: this.$t("PLACEHOLDERS.limited_period"),
            value: "limited",
          }
        } else {
          this.data.duration = {
            id: 2,
            name: this.$t("PLACEHOLDERS.unlimited_period"),
            value: "unlimited ",
          }
        }

        this.data.active = res.data.data.Coupon.is_active;
        // End:: Set Data
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
    // End:: Get Data To Edit

  },

  created() {
    this.getClients();
    this.getDataToEdit()
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{attrs:{"id":"navbar"}},[_c('div',{staticClass:"container p-0"},[_c('div',{staticClass:"navbar_wrapper"},[_c('div',{staticClass:"welcome_message"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("TITLES.hi"))+" "+_vm._s(_vm.getAuthenticatedUserData.name)+" ")]),_c('span',[_vm._v(" 🖐️ ")])]),_c('div',{staticClass:"navbar_btns_wrapper"},[_c('div',{staticClass:"group"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({},'button',attrs,false),on),[_c('v-badge',{attrs:{"content":_vm.notificationCount,"floating":""}},[_c('div',{staticClass:"notification_btn",on:{"click":function($event){$event.stopPropagation();return _vm.toggleNotificationsMenu();}}},[_c('i',{staticClass:" fal fa-bell"})])])],1)]}}])},[_c('span',{staticClass:"toolTip"},[_vm._v(_vm._s(_vm.$t("TOOLTIPS.notifications")))])]),_c('div',{staticClass:"group"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"theme_changer",attrs:{"aria-label":"theme_changer"},on:{"click":_vm.changeAppTheme}},'button',attrs,false),on),[(_vm.getAppTheme == 'light_theme')?_c('i',{staticClass:"fal fa-moon"}):_c('i',{staticClass:"fal fa-sun"})])]}}])},[_c('span',{staticClass:"toolTip"},[_vm._v(_vm._s(_vm.$t("TOOLTIPS.change_theme")))])]),_c('div',{staticClass:"lang_toggler_wrapper"},[(_vm.getAppLocale == 'ar')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"lang_toggeler_btn",on:{"click":function($event){return _vm.changeAppLanguage('en')}}},'button',attrs,false),on),[_vm._v(" En ")])]}}],null,false,3022358460)},[_c('span',{staticClass:"toolTip"},[_vm._v(_vm._s(_vm.$t("TOOLTIPS.change_language")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"lang_toggeler_btn pb-3",on:{"click":function($event){return _vm.changeAppLanguage('ar')}}},'button',attrs,false),on),[_vm._v(" ع ")])]}}])},[_c('span',{staticClass:"toolTip"},[_vm._v(_vm._s(_vm.$t("TOOLTIPS.change_language")))])])],1),_c('button',{staticClass:"small_screens_navbar_toggeler",on:{"click":function($event){return _vm.$emit('fireToggleNavDrawerEmit')}}},[_c('i',{staticClass:"fal fa-bars"})])],1),_c('div',{staticClass:"user_profile_menu_wrapper"},[_c('button',{staticClass:"user_profile_menu_btn",on:{"click":function($event){_vm.userProfileModalIsOpen = true}}},[_c('div',{staticClass:"name_wrapper"},[_c('h3',[_vm._v(_vm._s(_vm.getAuthenticatedUserData.name))])]),_c('div',{staticClass:"avatar_wrapper"},[_c('AvatarPlaceholder',{attrs:{"nameAvatar":_vm.getAuthenticatedUserData.name}})],1)])]),_c('UserDataModal',{attrs:{"modalIsOpen":_vm.userProfileModalIsOpen},on:{"toggleModal":function($event){_vm.userProfileModalIsOpen = !_vm.userProfileModalIsOpen}}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app>
    <transition name="fadeInUp" mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
import { mapGetters  , mapActions} from "vuex";

export default {
  name: "App",
   computed: {
    ...mapGetters({
      notificationCount: "NotificationsModule/getnotificationCount",
    }),

  },
  methods: {
     ...mapActions({
      getNotificationCount: "NotificationsModule/getNotificationCount",
    }),

  },
  created() {
     navigator.serviceWorker.addEventListener('message', event => {
      console.log("event", event)
       this.getNotificationCount();
    });
  }
};

</script>

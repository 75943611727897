<template>
  <div class="crud_form_wrapper">
    <!-- Start:: Title -->
    <div class="form_title_wrapper">
      <h4>{{ $t("PLACEHOLDERS.update_ads") }}</h4>
    </div>
    <!-- End:: Title -->

    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row">

          <div class="uploaded">
            <label for="file">{{ this.$t('PLACEHOLDERS.upload_media') }}</label>
            <input type="file" id="file" ref="fileInput" @change="handleFileChange">
            <div v-if="previewUrl" class="preview-container position-relative">
              <img v-if="isImage || data.type == 'image'" :src="previewUrl" alt="Preview Image">
              <video v-else :src="previewUrl" controls></video>
              <div class="close">
                <i class="fas fa-times" @click="removeFile"></i>
              </div>
            </div>
          </div>

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameAr')" v-model.trim="data.nameAr"
            required />
          <!-- End:: Name Input -->

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameEn')" v-model.trim="data.nameEn"
            required />

          <base-picker-input col="6" type="date" :disabledDate="disabledDate"
            :placeholder="$t('PLACEHOLDERS.start_date')" v-model.trim="data.publish_start_date" required />

          <base-picker-input col="6" type="date" :disabledDate="disabledDate" :placeholder="$t('PLACEHOLDERS.end_date')"
            v-model.trim="data.publish_end_date" required />

          <!-- End:: Name Input -->

          <!-- Start:: Deactivate Switch Input -->
          <div class="input_wrapper switch_wrapper my-5">
            <v-switch color="green" :label="data.active ? $t('PLACEHOLDERS.active') : $t('PLACEHOLDERS.notActive')"
              v-model="data.active" hide-details></v-switch>
          </div>
          <!-- End:: Deactivate Switch Input -->

          <!-- Start:: Submit Button Wrapper -->
          <div class="btn_wrapper">
            <base-button class="mt-2" styleType="primary_btn" :btnText="$t('BUTTONS.save')"
              :isLoading="isWaitingRequest" :disabled="isWaitingRequest" />
          </div>
          <!-- End:: Submit Button Wrapper -->
        </div>
      </form>
    </div>
    <!-- END:: Single Step Form Content -->
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CreateAds",

  data() {
    return {
      // Start:: Loader Control Data
      isWaitingRequest: false,
      // End:: Loader Control Data

      // Start:: Data Collection To Send
      data: {
        image: {
          path: null,
          file: null,
        },
        nameAr: null,
        nameEn: null,
        type: null,
        active: true,
        publish_start_date: null,
        publish_end_date: null
      },
      // End:: Data Collection To Send

      file: null,
      previewUrl: null,

      arabicRegex: /^[\u0600-\u06FF\s]+$/,
      EnRegex: /[\u0600-\u06FF]/,
    };
  },

  computed: {
    isImage() {
      return this.file && this.file.type.includes('image');
    }
  },

  methods: {

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        this.previewFile();
      }
    },
    previewFile() {
      const reader = new FileReader();
      reader.onload = () => {
        this.previewUrl = reader.result;
      };
      reader.readAsDataURL(this.file);
    },
    removeFile() {
      this.file = null;
      this.previewUrl = null;
      // Clear the file input
      this.$refs.fileInput.value = '';
    },

    disabledDate(current) {
      return current && current < moment().startOf("day");
    },

    onCopy(event) {
      event.preventDefault();
    },
    onPaste(event) {
      event.preventDefault();
    },

    validateInput() {
      // Remove non-Arabic characters from the input
      this.data.nameAr = this.data.nameAr.replace(/[^\u0600-\u06FF\s]/g, "");
    },
    removeArabicCharacters() {
      this.data.nameEn = this.data.nameEn.replace(this.EnRegex, "");
    },

    selectImage(selectedImage) {
      this.data.image = selectedImage;
    },

    // Start:: validate Form Inputs
    validateFormInputs() {
      this.isWaitingRequest = true;

      if (!this.data.nameAr) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.nameAr"));
        return;
      } else if (!this.data.nameEn) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.nameEn"));
        return;
      }
      else if (!this.data.publish_start_date) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.startDate"));
        return;
      }
      else if (!this.data.publish_end_date) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.endDate"));
        return;
      } else {
        this.submitForm();
        return;
      }
    },
    // End:: validate Form Inputs

    // Start:: Submit Form
    async submitForm() {

      const REQUEST_DATA = new FormData();
      // Start:: Append Request Data

      if (this.file) {
        REQUEST_DATA.append('type', this.isImage ? 'image' : 'video');
        REQUEST_DATA.append("advertisement", this.file);
      }

      REQUEST_DATA.append("name[ar]", this.data.nameAr);
      REQUEST_DATA.append("name[en]", this.data.nameEn);

      REQUEST_DATA.append("start_at", this.data.publish_start_date);
      REQUEST_DATA.append("end_at", this.data.publish_end_date);
      REQUEST_DATA.append("is_active", +this.data.active);
      REQUEST_DATA.append("_method", "PUT");
      // Start:: Append Request Data

      try {
        await this.$axios({
          method: "POST",
          url: `advertisements/${this.$route.params.id}`,
          data: REQUEST_DATA,
        });
        this.isWaitingRequest = false;
        this.$message.success(this.$t("MESSAGES.addedSuccessfully"));
        this.$router.push({ path: "/ads/all" });
      } catch (error) {
        this.isWaitingRequest = false;
        this.$message.error(error.response.data.message);
      }
    },
    // End:: Submit Form

    // start all ads data
    async getAdsData() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `advertisements/${this.$route.params.id}`,
        });
        this.previewUrl = res.data.data.Advertisement.advertisement;
        this.data.nameAr = res.data.data.Advertisement.name_ar;
        this.data.nameEn = res.data.data.Advertisement.name_en;
        this.data.type = res.data.data.Advertisement.type;
        this.data.publish_start_date = res.data.data.Advertisement.start_at;
        this.data.publish_end_date = res.data.data.Advertisement.end_at;
        this.data.active = res.data.data.Advertisement.is_active;
        // console.log(res.data.body.add_space)
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },
    // end all ads data

  },

  created() {
    // Start:: Fire Methods
    this.getAdsData()
    // End:: Fire Methods
  },
};
</script>


<style lang="scss" scoped>
.uploaded {
  input {
    display: none;
  }

  label {
    width: 100%;
    padding: 15px;
    background-color: #FFF;
  }

  .preview-container {
    max-width: 400px;
    max-height: 300px;
    margin: auto;

    img,
    video {
      width: 100%;
      height: 200px;
    }

    img {
      object-fit: cover;
    }

    .close {
      position: absolute;
      top: 10px;
      left: 10px;
      cursor: pointer;
      background: red;
      border-radius: 50px;
      width: 30px;
      height: 30px;
      color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
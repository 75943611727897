import Vue from "vue";
import VueRouter from "vue-router";

// Start:: Importing Middleware
import auth from "../middleware/auth.js";
// End:: Importing Middleware

// Start:: Importing Router Components
import Authentication from "../pages/Authentication.vue";
import LoginForm from "../views/Auth/LoginForm.vue";
import ResetPasswordEmailForm from "../views/Auth/ResetPasswordEmailForm.vue";
import EmailVerificationForm from "../views/Auth/EmailVerificationForm.vue";
import ChangePhoneNumberForm from "../views/Auth/ChangePhoneNumber.vue";
import ResetPasswordForm from "../views/Auth/ResetPasswordForm.vue";
import AppContentWrapper from "../pages/AppContentWrapper.vue";

// ============== Start:: Home Page Route
import HomePage from "../pages/HomePage.vue";
// ============== End:: Home Page Route

// ============== Start:: Clients Routes
import ClientsHome from "../views/Cruds/Clients/Home.vue";
import AllClients from "../views/Cruds/Clients/ShowAll.vue";
import ShowClient from "../views/Cruds/Clients/Show.vue";
// import ShowSubscription from "../views/Cruds/Clients/Subscription.vue";
// ============== End:: Clients Routes

// ============== Start:: Reminders Routes
import RemindersHome from "../views/Cruds/Reminders/Home.vue";
import AllReminders from "../views/Cruds/Reminders/ShowAll.vue";
import ShowReminders from "../views/Cruds/Reminders/Show.vue";
// ============== End:: Reminders Routes

// ============== Start:: Offers Routes
import OffersHome from "../views/Cruds/Offers/Home.vue";
import AllOffers from "../views/Cruds/Offers/ShowAll.vue";
import EditOffers from "../views/Cruds/Offers/Edit.vue";
import CreateOffers from "../views/Cruds/Offers/Create.vue";
import ShowOffer from "../views/Cruds/Offers/Show.vue";
// ============== End:: Offers Routes

// ============== Start:: Packages Routes
import PackagesHome from "../views/Cruds/Packages/Home.vue";
import AllPackages from "../views/Cruds/Packages/ShowAll.vue";
import CreatePackages from "../views/Cruds/Packages/Create.vue";
import ShowPackages from "../views/Cruds/Packages/Show.vue";
import EditPackages from "../views/Cruds/Packages/Edit.vue";
// ============== End:: Packages Routess

// ============== Start:: Category Routes
import CategoryHome from "../views/Cruds/Category/Home.vue";
import AllCategory from "../views/Cruds/Category/ShowAll.vue";
import CreateCategory from "../views/Cruds/Category/Create.vue";
import EditCategory from "../views/Cruds/Category/Edit.vue";
import ShowCategory from "../views/Cruds/Category/Show.vue";
// ============== End:: Category Routes

// ============== Start:: Rates Routes
import RatesHome from "../views/Cruds/Rates/Home.vue";
import AllRates from "../views/Cruds/Rates/ShowAll.vue";
import ShowRates from "../views/Cruds/Rates/Show.vue";
// ============== End:: Rates Routes

// ============== Start:: Financial Reports Routes
import FinancialReportsHome from "../views/Cruds/FinancialReports/Home.vue";
import AllFinancialReports from "../views/Cruds/FinancialReports/ShowAll.vue";
import ShowFinancialReport from "../views/Cruds/FinancialReports/Show.vue";
// ============== End:: Financial Reports Routes

// ============== Start:: Contact Messages Routes
import ContactMessagesHome from "../views/Cruds/ContactMessages/Home.vue";
import AllContactMessages from "../views/Cruds/ContactMessages/ShowAll.vue";
// ============== End:: Contact Messages Routes

// ============== Start:: Ads Routes
import AdsHome from "../views/Cruds/Ads/Home.vue";
import AllAds from "../views/Cruds/Ads/ShowAll.vue";
import CreateAds from "../views/Cruds/Ads/Create.vue";
import ShowAds from "../views/Cruds/Ads/Show.vue";
import EditAds from "../views/Cruds/Ads/Edit.vue";
// ============== End:: Ads Routes

// ============== Start:: App Content Routes
import AppContentHome from "../views/Cruds/AppContent/Home.vue";
import AboutUs from "../views/Cruds/AppContent/AboutUs.vue";
import Terms from "../views/Cruds/AppContent/Terms.vue";
import PrivacyPolicy from "../views/Cruds/AppContent/PrivacyPolicy.vue";
// import RaseelUses from "../views/Cruds/AppContent/RaseelUses.vue";
// import RaseelConditions from "../views/Cruds/AppContent/RaseelConditions.vue";
// ============== End:: App Content Routes

// ============== Start:: App Settings Routes
import AppSettingsHome from "../views/Cruds/AppSettings/Home.vue";
import GeneralSettings from "../views/Cruds/AppSettings/GeneralSettings.vue";
import AppSettings from "../views/Cruds/AppSettings/AppSettings.vue";
// ============== End:: App Settings Routes

// ============== Start:: Coupons Routes
import CouponsHome from "../views/Cruds/Coupons/Home.vue";
import AllCoupons from "../views/Cruds/Coupons/ShowAll.vue";
import CreateCoupon from "../views/Cruds/Coupons/Create.vue";
import EditCoupon from "../views/Cruds/Coupons/Edit.vue";
import ShowCoupon from "../views/Cruds/Coupons/Show.vue";
// ============== End:: Coupons Routes

// ============== Start:: Cities Routes
import CitiesHome from "../views/Cruds/Cities/Home.vue";
import AllCities from "../views/Cruds/Cities/ShowAll.vue";
import CreateCities from "../views/Cruds/Cities/Create.vue";
import EditCities from "../views/Cruds/Cities/Edit.vue";
import ShowCities from "../views/Cruds/Cities/Show.vue";
// ============== End:: Cities Routes

// ============== Start:: Areas Routes
import AreasHome from "../views/Cruds/Areas/Home.vue";
import AllAreas from "../views/Cruds/Areas/ShowAll.vue";
import CreateAreas from "../views/Cruds/Areas/Create.vue";
import EditAreas from "../views/Cruds/Areas/Edit.vue";
import ShowAreas from "../views/Cruds/Areas/Show.vue";
// ============== End:: Areas Routes

// ============== Start:: Districts Routes
import DistrictsHome from "../views/Cruds/Districts/Home.vue";
import AllDistricts from "../views/Cruds/Districts/ShowAll.vue";
import CreateDistricts from "../views/Cruds/Districts/Create.vue";
import EditDistricts from "../views/Cruds/Districts/Edit.vue";
import ShowDistricts from "../views/Cruds/Districts/Show.vue";
// ============== End:: Districts Routes

// ============== Start:: Roles Routes
import RolesHome from "../views/Cruds/Roles/Home.vue";
import AllRoles from "../views/Cruds/Roles/ShowAll.vue";
import CreateRole from "../views/Cruds/Roles/Create.vue";
import EditRole from "../views/Cruds/Roles/Edit.vue";
// ============== End:: Roles Routes

// ============== Start:: Admins Routes
import AdminsHome from "../views/Cruds/Admins/Home.vue";
import AllAdmins from "../views/Cruds/Admins/ShowAll.vue";
import CreateAdmin from "../views/Cruds/Admins/Create.vue";
import EditAdmin from "../views/Cruds/Admins/Edit.vue";
import ShowAdmin from "../views/Cruds/Admins/Show.vue";
// ============== End:: Admins Routes

// ============== Start:: Characteristics Routes
import CharacteristicsHome from "../views/Cruds/Characteristics/Home.vue";
import AllCharacteristics from "../views/Cruds/Characteristics/ShowAll.vue";
import CreateCharacteristics from "../views/Cruds/Characteristics/Create.vue";
import EditCharacteristics from "../views/Cruds/Characteristics/Edit.vue";
import ShowCharacteristics from "../views/Cruds/Characteristics/Show.vue";
// ============== End:: Characteristics Routes

// ============== Start:: All Notifications Routes
import NotificationsHome from "../views/Cruds/Notifications/Home.vue";
import AllNotifications from "../views/Cruds/Notifications/ShowAll.vue";
import CreateNotifications from "../views/Cruds/Notifications/Create.vue";
import ShowNotifications from "../views/Cruds/Notifications/Show.vue";
import SingleNotifications from "../views/Cruds/Notifications/SingleNotification.vue";
// ============== End:: All Notifications Routes

// ============== Start:: EstateDepartments Routes
import EstateDepartmentsHome from "../views/Cruds/EstateDepartments/Home.vue";
import AllEstateDepartments from "../views/Cruds/EstateDepartments/ShowAll.vue";
import CreateEstateDepartments from "../views/Cruds/EstateDepartments/Create.vue";
import EditEstateDepartments from "../views/Cruds/EstateDepartments/Edit.vue";
import ShowEstateDepartments from "../views/Cruds/EstateDepartments/Show.vue";
// ============== End:: EstateDepartments Routes

// ============== Start:: Banks Routes
import BanksHome from "../views/Cruds/Banks/Home.vue";
import AllBanks from "../views/Cruds/Banks/ShowAll.vue";
import CreateBanks from "../views/Cruds/Banks/Create.vue";
import EditBanks from "../views/Cruds/Banks/Edit.vue";
import ShowBanks from "../views/Cruds/Banks/Show.vue";
// ============== End:: Banks Routes

// ============== Start:: additionalFields Routes
import AdditionalFieldsHome from "../views/Cruds/AdditionalFields/Home.vue";
import AllAdditionalFields from "../views/Cruds/AdditionalFields/ShowAll.vue";
import CreateAdditionalFields from "../views/Cruds/AdditionalFields/Create.vue";
import ShowAdditionalFields from "../views/Cruds/AdditionalFields/Show.vue";
import EditAdditionalFields from "../views/Cruds/AdditionalFields/Edit.vue";
// ============== End:: additionalFields Routes

// ============== Start:: Forbidden Route
import ForbiddenPage from "../pages/ForbiddenPage.vue";
// ============== End:: Forbidden Route

// ============== Start:: Not Found Route
import NotFoundPage from "../pages/NotFound.vue";
// ============== End:: Not Found Route
// End:: Importing Router Components

Vue.use(VueRouter);
const routes = [
  // ***************** Start:: Auth Routes Config
  {
    path: "/",
    component: Authentication,
    children: [
      {
        path: "/",
        component: LoginForm,
        name: "LoginForm",
      },
      {
        path: "/reset-password-email",
        name: "ResetPasswordEmailForm",
        component: ResetPasswordEmailForm,
      },
      {
        path: "/email-verification/:verification_type",
        name: "EmailVerificationForm",
        component: EmailVerificationForm,
      },
      {
        path: "/chang-phone-number",
        name: "ChangePhoneNumberForm",
        component: ChangePhoneNumberForm,
      },
      {
        path: "/reset-password",
        name: "ResetPasswordForm",
        component: ResetPasswordForm,
      },
    ],
  },
  // ***************** End:: Auth Routes Config

  // ***************** Start:: Content Routes Config
  {
    path: "/home",
    component: AppContentWrapper,
    children: [
      // Start:: Home Route Config
      {
        path: "/home",
        component: HomePage,
        meta: {
          middleware: [auth],
        },
      },
      // End:: Home Route Config

      // Start:: Clients Routes Config
      {
        path: "/clients",
        name: "ClientsHome",
        component: ClientsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllClients",
            component: AllClients,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "clients index",
                subject: "clients",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowClient",
            component: ShowClient,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "clients show",
                subject: "clients",
              },
            },
          },
          // {
          //   path: "show-subscription/:id",
          //   name: "ShowSubscription",
          //   component: ShowSubscription,
          //   props: true,
          //   meta: {
          //     middleware: [auth],
          //     requiresPermission: {
          //       action: "clients show",
          //       subject: "clients",
          //     },
          //   },
          // },
        ],
      },
      // End:: Clients Routes Config

      // Start:: RemindersHome Routes Config
      {
        path: "/reminders",
        name: "RemindersHome",
        component: RemindersHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllReminders",
            component: AllReminders,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "reminders index",
                subject: "reminders",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowReminders",
            component: ShowReminders,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "reminders show",
                subject: "reminders",
              },
            },
          },
        ],
      },
      // End:: Reminders Routes Config

      // Start:: Category  Config
      {
        path: "/category",
        name: "CategoryHome",
        component: CategoryHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllCategory",
            component: AllCategory,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "categories index",
                subject: "categories",
              },
            },
          },
          {
            path: "create",
            name: "CreateCategory",
            component: CreateCategory,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "categories create",
                subject: "categories",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditCategory",
            component: EditCategory,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "categories edit",
                subject: "categories",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowCategory",
            component: ShowCategory,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "categories show",
                subject: "categories",
              },
            },
          },
        ],
      },
      // End:: Category Config

      // Start:: Offers Routes Config
      {
        path: "/offers",
        name: "OffersHome",
        component: OffersHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllOffers",
            component: AllOffers,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "offers index",
                subject: "offers",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowOffer",
            component: ShowOffer,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "offers show",
                subject: "offers",
              },
            },
          },
          {
            path: "create",
            name: "CreateOffers",
            component: CreateOffers,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "offers create",
                subject: "offers",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditOffers",
            component: EditOffers,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "offers edit",
                subject: "offers",
              },
            },
          },
        ],
      },
      // End:: Offers Routes Config

      // Start:: packages  Config
      {
        path: "/packages",
        name: "PackagesHome",
        component: PackagesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllPackages",
            component: AllPackages,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "packages index",
                subject: "packages",
              },
            },
          },
          {
            path: "create",
            name: "CreatePackages",
            component: CreatePackages,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "packages create",
                subject: "packages",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditPackages",
            component: EditPackages,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "packages edit",
                subject: "packages",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowPackages",
            component: ShowPackages,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "packages show",
                subject: "packages",
              },
            },
          },
        ],
      },
      // End:: Packages Config

      // Start:: Rates Routes Config
      {
        path: "/rates",
        name: "RatesHome",
        component: RatesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllRates",
            component: AllRates,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "rates index",
                subject: "rates",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowRates",
            component: ShowRates,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "rates show",
                subject: "rates",
              },
            },
          },
        ],
      },
      // End:: Rates Routes Config

      // Start:: Financial Reports Routes Config
      {
        path: "/financial-reports",
        name: "FinancialReportsHome",
        component: FinancialReportsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllFinancialReports",
            component: AllFinancialReports,
            meta: {
              middleware: [auth],
            },
          },
          // {
          //   path: "show/:id",
          //   name: "ShowFinancialReport",
          //   component: ShowFinancialReport,
          //   props: true,
          //   meta: {
          //     middleware: [auth],
          //   },
          // },
        ],
      },
      // End:: Financial Reports Routes Config

      // Start:: Coupons Config
      {
        path: "/coupons",
        name: "CouponsHome",
        component: CouponsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllCoupons",
            component: AllCoupons,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "coupons index",
                subject: "coupons",
              },
            },
          },
          {
            path: "create",
            name: "CreateCoupon",
            component: CreateCoupon,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "coupons index",
                subject: "coupons",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditCoupon",
            component: EditCoupon,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "coupons index",
                subject: "coupons",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowCoupon",
            component: ShowCoupon,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "coupons index",
                subject: "coupons",
              },
            },
          },
        ],
      },
      // End:: Coupons Config

      // Start:: Cities  Config
      {
        path: "/cities",
        name: "CitiesHome",
        component: CitiesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllCities",
            component: AllCities,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "countries index",
                subject: "countries",
              },
            },
          },
          {
            path: "create",
            name: "CreateCities",
            component: CreateCities,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "countries create",
                subject: "countries",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditCities",
            component: EditCities,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "countries edit",
                subject: "countries",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowCities",
            component: ShowCities,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "countries show",
                subject: "countries",
              },
            },
          },
        ],
      },
      // End:: Cities Config

      // Start:: Districts  Config
      {
        path: "/districts",
        name: "DistrictsHome",
        component: DistrictsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllDistricts",
            component: AllDistricts,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "districts index",
                subject: "districts",
              },
            },
          },
          {
            path: "create",
            name: "CreateDistricts",
            component: CreateDistricts,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "districts create",
                subject: "districts",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditDistricts",
            component: EditDistricts,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "districts edit",
                subject: "districts",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowDistricts",
            component: ShowDistricts,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "districts show",
                subject: "districts",
              },
            },
          },
        ],
      },
      // End:: Districts Config

      // Start:: Areas  Config
      {
        path: "/areas",
        name: "AreasHome",
        component: AreasHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllAreas",
            component: AllAreas,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "areas index",
                subject: "areas",
              },
            },
          },
          {
            path: "create",
            name: "CreateAreas",
            component: CreateAreas,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "areas create",
                subject: "areas",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditAreas",
            component: EditAreas,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "areas edit",
                subject: "areas",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowAreas",
            component: ShowAreas,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "areas show",
                subject: "areas",
              },
            },
          },
        ],
      },
      // End:: Areas Config

      // Start:: Ads  Config
      {
        path: "/ads",
        name: "AdsHome",
        component: AdsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllAds",
            component: AllAds,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "advertisements index",
                subject: "advertisements",
              },
            },
          },
          {
            path: "create",
            name: "CreateAds",
            component: CreateAds,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "advertisements create",
                subject: "advertisements",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditAds",
            component: EditAds,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "advertisements edit",
                subject: "advertisements",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowAds",
            component: ShowAds,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "advertisements show",
                subject: "advertisements",
              },
            },
          },
        ],
      },
      // End:: Ads Config

      // Start:: realEstateDepartments Routes Config
      {
        path: "/estate-departments",
        name: "EstateDepartmentsHome",
        component: EstateDepartmentsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllEstateDepartments",
            component: AllEstateDepartments,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "realEstateDepartments index",
                subject: "realEstateDepartments",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowEstateDepartments",
            component: ShowEstateDepartments,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "realEstateDepartments show",
                subject: "realEstateDepartments",
              },
            },
          },
          {
            path: "create",
            name: "CreateEstateDepartments",
            component: CreateEstateDepartments,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "realEstateDepartments create",
                subject: "realEstateDepartments",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditEstateDepartments",
            component: EditEstateDepartments,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "realEstateDepartments edit",
                subject: "realEstateDepartments",
              },
            },
          },
        ],
      },
      // End:: realEstateDepartments Routes Config

      // Start:: additionalFields  Config
      {
        path: "/additionalFields",
        name: "AdditionalFieldsHome",
        component: AdditionalFieldsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllAdditionalFields",
            component: AllAdditionalFields,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "additionalFields index",
                subject: "additionalFields",
              },
            },
          },
          {
            path: "create",
            name: "CreateAdditionalFields",
            component: CreateAdditionalFields,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "additionalFields create",
                subject: "additionalFields",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditAdditionalFields",
            component: EditAdditionalFields,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "additionalFields edit",
                subject: "additionalFields",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowAdditionalFields",
            component: ShowAdditionalFields,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "additionalFields show",
                subject: "additionalFields",
              },
            },
          },
        ],
      },
      // End:: additionalFields Config

      // Start:: banks Routes Config
      {
        path: "/banks",
        name: "BanksHome",
        component: BanksHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllBanks",
            component: AllBanks,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "banks index",
                subject: "banks",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowBanks",
            component: ShowBanks,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "banks show",
                subject: "banks",
              },
            },
          },
          {
            path: "create",
            name: "CreateBanks",
            component: CreateBanks,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "banks create",
                subject: "banks",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditBanks",
            component: EditBanks,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "banks edit",
                subject: "banks",
              },
            },
          },
        ],
      },
      // End:: banks Routes Config

      // Start:: Contact Messages Routes Config
      {
        path: "/contact-messages",
        name: "ContactMessagesHome",
        component: ContactMessagesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllContactMessages",
            component: AllContactMessages,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "contacts index",
                subject: "contacts",
              },
            },
          },
        ],
      },
      // End:: Contact Messages Routes Config

      // Start:: App Content Routes Config
      {
        path: "/app-content",
        name: "AppContentHome",
        component: AppContentHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "about-us",
            name: "AboutUs",
            component: AboutUs,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "settings create",
                subject: "settings",
              },
            },
          },
          {
            path: "terms",
            name: "Terms",
            component: Terms,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "settings create",
                subject: "settings",
              },
            },
          },
          {
            path: "policy",
            name: "PrivacyPolicy",
            component: PrivacyPolicy,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "settings create",
                subject: "settings",
              },
            },
          },
          // {
          //   path: "Raseel-uses",
          //   name: "RaseelUses",
          //   component: RaseelUses,
          //   meta: {
          //     middleware: [auth],
          //     requiresPermission: {
          //       action: "settings create",
          //       subject: "settings",
          //     },
          //   },
          // },
          // {
          //   path: "Raseel-conditions",
          //   name: "RaseelConditions",
          //   component: RaseelConditions,
          //   meta: {
          //     middleware: [auth],
          //     requiresPermission: {
          //       action: "settings create",
          //       subject: "settings",
          //     },
          //   },
          // },
        ],
      },
      // End:: App Content Routes Config

      // Start:: App Settings Routes Config
      {
        path: "/settings",
        component: AppSettings,
        meta: {
          middleware: [auth],
          requiresPermission: {
            action: "settings create",
            subject: "settings",
          },
        },
      },
      // End:: App Settings Routes Config

      // Start:: App Settings Routes Config
      {
        path: "/contact_settings",
        component: GeneralSettings,
        meta: {
          middleware: [auth],
          requiresPermission: {
            action: "settings create",
            subject: "settings",
          },
        },
      },
      // End:: App Settings Routes Config

      // Start:: Roles Routes Config
      {
        path: "/roles",
        name: "RolesHome",
        component: RolesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllRoles",
            component: AllRoles,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "roles index",
                subject: "roles",
              },
            },
          },
          {
            path: "create",
            name: "CreateRole",
            component: CreateRole,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "roles create",
                subject: "roles",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditRole",
            component: EditRole,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "roles edit",
                subject: "roles",
              },
            },
          },
        ],
      },
      // End:: Roles Routes Config

      // Start:: characteristics Routes Config
      {
        path: "/characteristics",
        name: "characteristicsHome",
        component: CharacteristicsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllCharacteristics",
            component: AllCharacteristics,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "characteristics index",
                subject: "characteristics",
              },
            },
          },
          {
            path: "create",
            name: "CreateCharacteristics",
            component: CreateCharacteristics,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "characteristics create",
                subject: "characteristics",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditCharacteristics",
            component: EditCharacteristics,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "characteristics edit",
                subject: "characteristics",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowCharacteristics",
            component: ShowCharacteristics,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "characteristics show",
                subject: "characteristics",
              },
            },
          },
        ],
      },
      // End:: characteristics Routes Config

      // Start:: Admins Routes Config
      {
        path: "/admins",
        name: "AdminsHome",
        component: AdminsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllAdmins",
            component: AllAdmins,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "admins index",
                subject: "admins",
              },
            },
          },
          {
            path: "create",
            name: "CreateAdmin",
            component: CreateAdmin,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "admins create",
                subject: "admins",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditAdmin",
            component: EditAdmin,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "admins edit",
                subject: "admins",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowAdmin",
            component: ShowAdmin,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "admins show",
                subject: "admins",
              },
            },
          },
        ],
      },
      // End:: Admins Routes Config

      // Start:: All Notifications Route Config
      {
        path: "/all-notifications",
        name: "NotificationsHome",
        component: NotificationsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllAdmins",
            component: AllNotifications,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "notifications index",
                subject: "notifications",
              },
            },
          },
          {
            path: "create",
            name: "CreateNotificationss",
            component: CreateNotifications,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "notifications create",
                subject: "notifications",
              },
            },
          },
          {
            path: "show",
            name: "ShowNotifications",
            component: ShowNotifications,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "notifications show",
                subject: "notifications",
              },
            },
          },
          {
            path: "single-notification/:id",
            name: "SingleNotifications",
            component: SingleNotifications,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "notifications show",
                subject: "notifications",
              },
            },
          },
        ],
      },
      // End:: All Notifications Route Config
    ],
  },
  // ***************** End:: Content Routes Config

  // ***************** Start:: Forbidden Route Config
  {
    path: "/forbidden",
    name: "ForbiddenPage",
    component: ForbiddenPage,
  },
  // ***************** End:: Forbidden Route Config

  // ***************** Start:: Not Found Route Config
  {
    path: "/:notFound(.*)",
    name: "NotFoundPage",
    component: NotFoundPage,
  },
  // ***************** End:: Not Found Route Config
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
  routes,
});

// Start:: Middleware And Router Guards
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

// permissions

router.beforeEach((to, from, next) => {
  // console.log(to);
  // Check if the route requires permission
  if (to.meta && to.meta.requiresPermission) {
    const { action, subject } = to.meta.requiresPermission;

    // Retrieve permissions from localStorage
    const storedPermissions = JSON.parse(
      localStorage.getItem("Raseel_admin_roles")
    )?.permissions;

    // Check if the user has the required permission
    if (
      storedPermissions.some(
        (permission) =>
          permission.action === action && permission.subject === subject
      )
    ) {
      next(); // User has permission, allow access to the route
    } else {
      // User doesn't have permission, redirect to a fallback route or show an error message
      next("/forbidden"); // Replace '/fallback' with the route path or name of your fallback route
    }
  } else {
    next(); // Route doesn't require permission, allow access to the route
  }
});

// authentication
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  } else if (localStorage.getItem("Raseel_admin_dashboard_user_token")) {
    if (
      to.name == "LoginForm" ||
      to.name == "ResetPasswordEmailForm" ||
      to.name == "PhoneVerification" ||
      to.name == "ChangePhoneNumberForm" ||
      to.name == "ResetPasswordForm"
    ) {
      return next("/home");
    } else {
      return next();
    }
  }
  return next();
});
// End:: Middleware And Router Guards

export default router;

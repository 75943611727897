<template>
  <div class="crud_form_wrapper">
    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row">

          <!-- Start:: Name Input -->
          <!-- <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.VAT_percentage')"
            v-model.trim="data.VAT_percentage" /> -->
          <!-- End:: Name Input -->

          <!-- Start:: Status Input -->
          <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.free_trial_period')"
            v-model="data.free_trial_period" />
          <!-- End:: Status Input -->

          <!-- <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.paid_subscription_duration')"
            v-model="data.paid_subscription_duration" /> -->

          <!-- <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.paid_subscription_amount')"
            v-model="data.paid_subscription_amount" /> -->

          <!-- <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.subscription_expiry_reminder_duration')"
            v-model="data.subscription_expiry_reminder_duration" /> -->

          <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.reminder_notification_time')"
            v-model="data.reminder_notification_time" />

          <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.reminder_notification_frequency')"
            v-model="data.reminder_notification_frequency" />

          <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.delete_chat_after_days')"
            v-model="data.delete_chat_after_days" />

          <!-- Start:: Submit Button Wrapper -->
          <div class="btn_wrapper">
            <base-button class="mt-2" styleType="primary_btn" :btnText="$t('BUTTONS.save')" :isLoading="isWaitingRequest"
              :disabled="isWaitingRequest" />
          </div>
          <!-- End:: Submit Button Wrapper -->
        </div>
      </form>
    </div>
    <!-- End:: Single Step Form Content -->
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
export default {
  name: "GeneralSettings",

  data() {
    return {
      // Start:: Loader Control Data
      isWaitingRequest: false,
      // End:: Loader Control Data

      // Start:: Data
      data: {
        VAT_percentage: null,
        free_trial_period: null,
        paid_subscription_duration: null,
        paid_subscription_amount: null,
        subscription_expiry_reminder_duration: null,
        reminder_notification_time: null,
        reminder_notification_frequency: null,
        delete_chat_after_days: null,
      },
      // End:: Data

    };
  },

  computed: {
    // Start:: Vuex Getters
    ...mapGetters({
      getAuthenticatedUserData: "AuthenticationModule/getAuthenticatedUserData"
    }),
    // End:: Vuex Getters
  },


  methods: {

    // Start:: Get Data To Edit
    async getDataToEdit() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `settings?key=dashboard_settings`,
        });
        // console.log("DATA =>", res.data.data);
        this.data.VAT_percentage = res.data.data[0].value.tax;
        this.data.delete_chat_after_days = res.data.data[0].value.delete_chat_duration;
        this.data.free_trial_period = res.data.data[0].value.free_subscription_duration;
        this.data.reminder_notification_time = res.data.data[0].value.repeate_reminder_duration;
        this.data.reminder_notification_frequency = res.data.data[0].value.repeate_reminder_count;

        // this.data.paid_subscription_amount = res.data.data[0].value.paid_subscription_amount;
        // this.data.paid_subscription_duration = res.data.data[0].value.paid_subscription_duration;
        // this.data.subscription_expiry_reminder_duration = res.data.data[0].value.subscription_expiry_reminder_duration;

      } catch (error) {
        console.log(error.response.data.message);
      }
    },
    // End:: Get Data To Edit

    // Start:: Submit Form
    async submitForm() {
      this.isWaitingRequest = !this.isWaitingRequest;

      const REQUEST_DATA = new FormData();
      // Start:: Append Request Data
      REQUEST_DATA.append("key", "dashboard_settings");
      // REQUEST_DATA.append("value[tax]", this.data.VAT_percentage);
      // REQUEST_DATA.append("value[paid_subscription_amount]", this.data.paid_subscription_amount);
      // REQUEST_DATA.append("value[paid_subscription_duration]", this.data.paid_subscription_duration);
      // REQUEST_DATA.append("value[subscription_expiry_reminder_duration]", this.data.subscription_expiry_reminder_duration);

      REQUEST_DATA.append("value[free_subscription_duration]", this.data.free_trial_period);
      REQUEST_DATA.append("value[delete_chat_duration]", this.data.delete_chat_after_days);
      REQUEST_DATA.append("value[repeate_reminder_count]", this.data.reminder_notification_frequency);
      REQUEST_DATA.append("value[repeate_reminder_duration]", this.data.reminder_notification_time);

      // Start:: Append Request Data

      try {
        await this.$axios({
          method: "POST",
          url: `settings`,
          data: REQUEST_DATA,
        });
        this.isWaitingRequest = false;
        this.$message.success(this.$t("MESSAGES.savedSuccessfully"));
        this.getDataToEdit();
      } catch (error) {
        this.isWaitingRequest = false;
        this.$message.error(error.response.data.errors);
      }
    },
    // End:: Submit Form

    // Start:: validate Form Inputs
    validateFormInputs() {
      this.isWaitingRequest = true;

      if (!this.data.free_trial_period || !this.data.delete_chat_after_days || !this.data.reminder_notification_frequency || !this.data.reminder_notification_time) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.phoneVerificationInputs"));
        return;
      } else {
        this.submitForm();
        return;
      }
    },
    // End:: validate Form Inputs
  },

  created() {
    // Start:: Fire Methods
    this.getDataToEdit();
    // End:: Fire Methods
  },
};
</script>


<style lang="scss" scoped>
.all_action {
  display: flex;
  gap: 15px
}

.add_another {
  border: none;
  padding: 8px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--light_gray_clr);
  border-radius: 50%;
  font-size: 18px;
  color: var(--light_gray_clr);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: auto;

  .fa-trash {
    color: #ff2159;
    cursor: pointer
  }
}
</style>